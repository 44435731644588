import DefaultLayout from "layouts/Default";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import BrandLayout from "layouts/BrandLayout";
import {wrapper} from "lib/store";
import React, {useEffect, useState} from "react";
import {getServiceCategories} from "lib/services/product";
import {useSelector, useDispatch} from "react-redux";
import ProductCard from "components/cards/ProductCard";
import MyList from "components/MyList";
import {getDealsByProductCategory} from "lib/services/deals";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button, Checkbox, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useRouter} from "next/router";
import {getBrand} from "lib/features/brandSlice";
import config from "../../../../data/config.json";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import {getPaginatedProductsThunk, getPaginatedServicesThunk} from "lib/features/productSlice";

const SORT_OPTIONS = config.product.sort
const PRICE_RANGES = config.product.priceRange

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
    const {
        query,locale,
    } = context
    let CATEGORIES = await getServiceCategories()
    CATEGORIES.map(c => ({name: c, slug: _.kebabCase(c)}))
    const allDeals = await getDealsByProductCategory()
    await store.dispatch(getBrand({slug: query.brandSlug, id: query.id}))
    const {
        page = 1,
        sort = {},
        deals = [],
        priceRanges = [],
        categories = [],
    } = query

    const filter = {
        deals,
        priceRanges,
        categories,
    }
    return {
        props: {
            allDeals,
            page,
            filter,
            sort,
            CATEGORIES,
            ...(await serverSideTranslations(locale, ['home', 'common']))

        }
    }
})

export default function Services(props) {
    const {
        page: _page,
        filter: _filter,
        sort: _sort,
        allDeals: deals,
        CATEGORIES
    } = props
    const [sort, setSort] = useState(SORT_OPTIONS.find(s => s.sort.sortBy === _sort.sortBy && s.sort.order === _sort.order) || SORT_OPTIONS[0])

    const handleSortChange = (event) => {
        setSort(event.target.value)
    }
    const brand = useSelector(state => state.brand.brand)
    const services = useSelector(state => state.product.services)
    const dispatch = useDispatch()
    const [filter, setFilter] = useState(_filter)
    const [filteredServices, setFilteredServices] = useState([])
    const { t } = useTranslation()

    const handleFilterChange = (field, value) => {
        switch (field) {
            case 'deals':
                setFilter({
                    ...filter,
                    deals: _.xor(filter.deals, [value])
                })
                break
            case 'priceRange':
                setFilter({
                    ...filter,
                    priceRange: value
                })
                break
            case 'categories':
                setFilter({
                    ...filter,
                    categories: _.xor(filter.categories, [value])
                })
                break
        }
    }
    useEffect(() => {
        if (services && brand.services) {
            let tempFilteredServices = services.filter(item => brand.services.split(', ').includes(item.id))

            setFilteredServices(tempFilteredServices)
        }

    }, [services])

    useEffect(() => {
        if (services.length === 1 && services[0].id === 0 ) {
            dispatch(getPaginatedServicesThunk({_page, _filter, _sort}))
        }
    }, [])

    return (
        <Wrapper className={'pt-2'}>
            <Container style={{ marginTop: 20 }} >
                <div className="flex gap-8">
                    <div className="w-3/12">
                        <div className="flex items-center pb-2 border-b mb-4">
                            <FontAwesomeIcon icon={solid('sliders')}/>
                            <span className={'ml-2 font-semibold'}>{t('home:filter')}</span>
                            <Button variant={'text'} className={'ml-auto'}>
                                {t('home:clearall')}
                            </Button>
                        </div>
                        {/*<div className="mb-4">*/}
                        {/*    <div className="font-semibold text-sm mb-2">Deals</div>*/}
                        {/*    {*/}
                        {/*        brand.deals.map(d => (*/}
                        {/*            <FormControlLabel*/}
                        {/*                key={d.slug}*/}
                        {/*                label={<span className={'text-sm'}>{d.name}</span>}*/}
                        {/*                className={'flex items-center -mb-1'}*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={filter.deals.includes(d.slug)}*/}
                        {/*                        onChange={e => handleFilterChange('deals', d.slug)}*/}
                        {/*                        size={'small'} className={'text-primary-dark'}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        ))*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="mb-4">
                            <div className="font-semibold text-sm mb-2">{t('home:filter_category')}</div>
                            {
                                CATEGORIES.map(c => (
                                    <FormControlLabel
                                        key={c.slug}
                                        label={<span className={'text-sm'}>{t(`home:${c.slug}`)}</span>}
                                        className={'flex items-center -mb-1'}
                                        control={
                                            <Checkbox
                                                checked={filter.categories.includes(c.slug)}
                                                onChange={e => handleFilterChange('categories', c.slug)}
                                                size={'small'} className={'text-primary-dark'}
                                            />
                                        }
                                    />
                                ))
                            }
                        </div>
                        <div className="mb-4">
                            <div className="font-semibold text-sm mb-2">{t('home:filter_strainType')}</div>
                            {
                                PRICE_RANGES.map(r => (
                                    <FormControlLabel
                                        key={`${String(r.min)}-${String(r.max)}`}
                                        label={<span className={'text-sm'}>{`${r.min === 0 ? t('home:under') : '$'+r.min}${r.min === 0 || r.max === Infinity ? ' ' : ' - '}${r.max === Infinity ? 'and over' : '$'+r.max}`}</span>}
                                        className={'flex items-center -mb-1'}
                                        control={
                                            <Checkbox
                                                checked={filter.priceRanges.includes(r)}
                                                onChange={e => handleFilterChange('priceRanges', r)}
                                                size={'small'} className={'text-primary-dark'}
                                            />
                                        }
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex-grow">
                        <FormControl fullWidth size={'small'} className={'mt-1'}>
                            <OutlinedInput
                                placeholder={t('home:searchThisMenu')}
                                id={'menu-search'}
                                endAdornment={
                                    <FontAwesomeIcon icon={solid('search')}/>
                                }
                            />
                        </FormControl>
                        <div className="flex py-4 items-center">
                            <Select
                                value={sort}
                                onChange={handleSortChange}
                                size={'small'} className={"w-52 text-sm bg-white ml-auto"}
                            >
                                {
                                    SORT_OPTIONS.map(option => (
                                        <MenuItem key={option.label} value={option}>{t(`home:${option.key}`)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                        {
                            filteredServices.length > 0 ?
                                <MyList itemComponent={ProductCard} dataList={filteredServices} title={''} sort={sort} setSort={setSort} baseUrl={`/services/`} />
                                :
                                <h1>
                                    {t('home:notFound')}
                                </h1>
                        }
                    </div>
                </div>
            </Container>
        </Wrapper>
    )
}

Services.getLayout = function getLayout(page) {
    return (
        <DefaultLayout>
            <BrandLayout>
                {page}
            </BrandLayout>
        </DefaultLayout>
    )
}

import ImageWithFallback from "components/ImageWithFallback";
import cn from "clsx";
import Link from "next/link";
import Rating from "components/Rating";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React, {useEffect, useState} from "react";
import config from '../../data/config.json'
import {useTranslation} from "next-i18next";


export default function ProductCard({data: product, ...props}) {
  const {
    plain, showDistance, hideCTA, baseUrl, type, brandInfo
  } = props

  const [productName, setProductName] = useState('Name')

  useEffect(() => {
    // console.log('product: ', product )
    if (product.slug !== '') {
      setProductName(product.slug)
    }
  }, [])
  const { t } = useTranslation()

  return (
    !!product ?
        <div key={product.slug}
             className={cn([
               'flex flex-col relative',
               !plain && 'shadow-md rounded overflow-hidden p-4'
             ])}
        >
          {/*{*/}
          {/*  !plain && product.deal &&*/}
          {/*  <div className="absolute top-0 left-0 pl-4 pr-8 py-1 z-10 bg-primary-dark text-white text-xs font-bold"*/}
          {/*      style={{clipPath: 'polygon(0 0, 100% 0%, calc(100% - 1em) 100%, 0 100%)'}}*/}
          {/*  >*/}
          {/*    10% off*/}
          {/*  </div>*/}
          {/*}*/}
          {
            !plain && product.brand_verified &&
            <FontAwesomeIcon
                icon={solid("certificate")}
                className={'text-primary absolute top-1 right-4 z-10'}
            />
          }

          <Link href={!!baseUrl && !baseUrl.includes('undefined') ? `${baseUrl}/${encodeURIComponent(productName)}/${product.id}` : `${product['__typename'] === 'Product' ? '/products' : '/services'}/listing/${encodeURIComponent(productName)}/${product.id}`} passHref>
            <div className={cn(["relative after:content-[''] after:block", 'after:pb-[75%] mb-2'])}>
              {
                !!product.images ?
                    <ImageWithFallback
                        src={product.images[0]}
                        alt={product.name}
                        className="object-cover transition hover:brightness-105 cursor-pointer"
                    /> : null
              }
            </div>
          </Link>
          <div className="text-xs text-gray-500 mb-1 flex">
            {type === 'Product' ? config.product.category_mapping[product.category] === undefined ? '' : t(`home:${product.category}`) : config.service.category_mapping[product.category] === undefined ? ' ' : t(`home:${product.category}`)}
            {
              showDistance && <span className="ml-auto text-primary font-semibold">
          {`${product.location.distance} ${t('home:distance')}`}
        </span>
            }
          </div>
          <Link href={!!baseUrl ? `${baseUrl}/${encodeURIComponent(productName)}/${product.id}` : `${product['__typename'] === 'Product' ? 'products' : 'services'}/${!!product.category ? product.category.split('_')[0] : ''}/${encodeURIComponent(productName)}/${product.id}`} passHref>
            <a>
              <h4 className="font-bold text-base mb-1 min-h-[80px] flex items-center">
                {product.name}
              </h4>
            </a>
          </Link>
          {
            !!product.brand && product.brand.name !== '' ?
                <div className="text-xs text-gray-500 mb-2">
                  By {product.brand.name}
                </div> : !!brandInfo ?
                <div className="text-xs text-gray-500 mb-2">
                  By {brandInfo.name}
                </div> : null
          }
          <div className="flex flex-grow flex-nowrap items-center mb-4 text-gray-500">
            {
              product.rating ?
                  <>
                    <div className="text-xs mr-2">
                      {product.rating.toFixed(1)}
                    </div>
                    <Rating
                        value={product.rating || 5}
                        precision={0.5}
                        readOnly
                        className={'mr-2'}
                    />
                  </>: null
            }
            {
              product.reviews_count ?
                  <div className="text-xs">
                    ({product.reviews_count})
                  </div> : null
            }

          </div>
          <div className="text-xs text-gray-500">
            {product.weight === 'Each' ? t('home:each') : product.weight}
          </div>
          <div className="flex mb-2">
            {
              product.price > 0 ?
                  <div className="font-semibold text-2xl leading-0">
                    ${product.price_compare > 0 ? product.price_compare : product.price}
                  </div> : null
            }
            {
              product.deal && <div className="ml-auto text-gray-500 font-semibold line-through leading-normal">
                ${product.price_compare > 0  ? product.price : ''}
              </div>
            }
          </div>
          {
            !hideCTA && <Button variant={'contained'} size={'small'} className={'shadow-none'}>
              <FontAwesomeIcon icon={solid('bag-shopping')} className={'mr-2'}/>
              {t('home:button_ordernow')}
            </Button>
          }
        </div> : <div>
      <span>{t('home:loading')}</span>
        </div>
  )
}
